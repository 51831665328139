import React, { useEffect } from "react";
import styled from "styled-components";
import { upperFirst } from "lodash";
import { useHistory } from "react-router-dom";
import { routes } from "routes";

import { useAppDispatch, useAppSelector } from "store";
import { getDayMonthYearFromDate } from "utils/formatters";
import { getInternationalDateFormat } from "utils/getInternationalDateFormat";
import {
  clearState,
  setCurrentEditedRow,
  setCurrentPage,
  setShowDeleteConfirmation,
  updateRedactionListSorting,
} from "store/redaction/redactionListSlice";
import { setSearchTerm } from "store/search/searchbarSlice";
import { fetchRedactionLists } from "store/redaction/thunks";
import { deleteRedactionList } from "services/redactions";
import useTableSize from "hooks/useTableSize";
import { showToastError, showToastSuccess } from "store/toast/toastSlice";

import { SearchRow } from "components/SearchRow";
import { Pagination } from "components/Pagination";
import { EmptyState } from "components/EmptyState";
import { Button } from "components/_buttons/Button";
import { StyledTablePageHeader, StyledTablePageTitle } from "components/Table/TablePageHeader";
import { ErrorScreen } from "components/ErrorScreen";
import { FlexTable, FlexTableSorting } from "components/FlexTable";
import { TextTruncator } from "components/TextTruncator";
import { ActionConfirmationModal } from "components/_modals/ActionConfirmationModal";
import { Text } from "components/Text";
import { ListPageHeaderPlaceholder } from "common-layouts/ListPagePlaceholder/ListPageHeaderPlaceholder";
import { TableInformation } from "components/Table/ClickableTableHeader";

import emptySvg from "assets/images/redaction-list-empty-state.svg";
import { FlexTableSize, FlexTableType } from "ts/enums/flexTable";
import { Color } from "ts/enums/color";
import { RedactionListSortingParameter, RedactionListType, RoleType } from "@explorance/mly-types";
import { PageErrorType } from "ts/enums/pageErrorType";
import { IsFeatureActive } from "utils/isFeatureActive";
import { Feature } from "ts/enums/feature";

const REDACTION_HEADERS: TableInformation<RedactionListSortingParameter>[] = Object.values(
  RedactionListSortingParameter
).map((vm) => ({
  headerName: vm,
  sortingParameter: RedactionListSortingParameter[upperFirst(vm)],
}));

export const RedactionList = () => {
  const { currentUser } = useAppSelector((state) => state.auth);
  const state = useAppSelector((state) => state.redactionList);
  const tableSize = useTableSize();

  const dispatch = useAppDispatch();
  const history = useHistory();

  // functions
  const updateSorting = (updatedSorting: FlexTableSorting) => {
    const { columnIndex, order } = updatedSorting;
    const sortColumn = REDACTION_HEADERS[columnIndex].headerName as RedactionListSortingParameter;

    dispatch(updateRedactionListSorting({ sortColumn, sortOrder: order }));
    dispatch(fetchRedactionLists());
  };

  const deleteRedaction = async () => {
    try {
      await deleteRedactionList(state.currentEditedRow.id);
      dispatch(setShowDeleteConfirmation(false));
      dispatch(fetchRedactionLists());
    } catch (err: any) {
      if (err.response.status === 401) {
        dispatch(setShowDeleteConfirmation(false));
        dispatch(showToastError("toast.redactionList.error.delete"));
      }
    } finally {
      dispatch(showToastSuccess("toast.redactionVersionDeleted"));
    }
  };

  const getDotsMenuContents = (isSystemRedactionSet: boolean) => {
    return [
      {
        label: (
          <Text
            resource={
              currentUser.roleType === RoleType.Analyst && isSystemRedactionSet
                ? "redaction.view"
                : "redaction.edit"
            }
          />
        ),
        onClick: () => history.push(routes.editRedactionPage(state.currentEditedRow.id)),
      },
      {
        label: <Text resource="button.delete" />,
        onClick: () => dispatch(setShowDeleteConfirmation(true)),
        isDisabled: currentUser.roleType === RoleType.Analyst && isSystemRedactionSet,
      },
    ];
  };

  const onSearch = () => {
    dispatch(setCurrentPage(1));
    dispatch(fetchRedactionLists());
  };

  const handlePageSelection = (page: number) => {
    dispatch(setCurrentPage(page));
    dispatch(fetchRedactionLists());
  };

  useEffect(() => {
    dispatch(fetchRedactionLists());
    // Reset state when component unmounts
    return () => {
      dispatch(clearState());
    };
  }, [dispatch]);

  if (!IsFeatureActive(Feature.Redaction)) {
    return <ErrorScreen errorType={PageErrorType.RedactionFeatureNotActivated} />;
  } else if (currentUser.roleType === RoleType.Viewer) {
    return <ErrorScreen errorType={PageErrorType.GeneralInsufficientPermission} />;
  }

  return (
    <>
      {state.isLoading ? (
        <ListPageHeaderPlaceholder
          showCreateButton
          showFilterButton={false}
          applyMorePadding
          buttonPlaceholderWidth={200}
          searchInputPlaceholderWidth={tableSize === FlexTableSize.Full ? 400 : 200}
        />
      ) : (
        <div className="fade-in">
          <ActionConfirmationModal
            isOpen={state.showDeleteConfirmation}
            title={
              <Text
                resource={{
                  key: "modal.deleteWithName.title",
                  args: [state.currentEditedRow?.name],
                }}
              />
            }
            caption={<Text resource="modal.deleteRedaction.caption" />}
            actionButtonLabel={<Text resource="button.delete" />}
            onClose={() => {
              dispatch(setShowDeleteConfirmation(false));
            }}
            onCancel={() => dispatch(setShowDeleteConfirmation(false))}
            onClickActionButton={deleteRedaction}
          />
          <StyledTablePageHeader>
            <StyledHeaderTopRow>
              <StyledRedactionListTitle>
                <Text resource="redaction.list.title" />
              </StyledRedactionListTitle>
              <SearchRow
                dataType="searchBar.dataType.redaction"
                currentCount={state.currentRedactionCount}
                totalCount={state.totalRedactionCount}
                onSearch={onSearch}
              />
              <StyledButtonBox>
                <Button onClick={() => history.push(routes.createRedaction)}>
                  <Text resource="button.createRedaction" />
                </Button>
              </StyledButtonBox>
            </StyledHeaderTopRow>
          </StyledTablePageHeader>
          {state.currentRedactionCount > 0 && (
            <StyledPaginationContainer>
              <Pagination
                currentPage={state.currentPage}
                currentItemsCount={state.currentRedactionCount}
                handlePageSelection={handlePageSelection}
              />
            </StyledPaginationContainer>
          )}
        </div>
      )}
      {state.totalRedactionCount > 0 || state.isLoading ? (
        <FlexTable
          type={FlexTableType.Table}
          data={{
            headers: REDACTION_HEADERS.map((h, i) => (
              <Text key={i} resource={`redaction.table.[${h.headerName}]`} />
            )).concat(<></>),
            rows: state.redactionSets?.map((redaction) => ({
              contentId: redaction.id,
              data: [
                <StyledRedactionName key={redaction.id}>
                  <TextTruncator
                    value={<b>{redaction.name}</b>}
                    id={redaction.id}
                    key={redaction.id}
                    customWidth="85%"
                  />
                  {redaction.type === RedactionListType.System && (
                    <StyledCreatedByAdminLabel>
                      <Text resource="redaction.label.createdByAdmin" />
                    </StyledCreatedByAdminLabel>
                  )}
                </StyledRedactionName>,
                <span key={`type-${redaction.id}`}>
                  <Text resource={`redaction.type.[${redaction.type}]`} />
                </span>,
                <span key={`terms-${redaction.id}`}>{redaction.termCount}</span>,
                <span key={`last-updated-${redaction.id}`}>
                  {getInternationalDateFormat(getDayMonthYearFromDate(redaction.lastUpdated))}
                </span>,
              ],
              dotsMenuParams: {
                rowId: redaction.id,
                currentEditedRowId: state.currentEditedRow?.id,
                menuContents: getDotsMenuContents(redaction.type === RedactionListType.System),
                onDotMenuClick: () => dispatch(setCurrentEditedRow(redaction)),
              },
            })),
            columnWidths: ["42.5%", "17.5%", "20%", "15%", "5%"],
            headerMinWidths: [155, 35, 35, 35],
          }}
          handleDoubleClickRow={(id) => history.push(routes.editRedactionPage(id))}
          customStyles={{
            rows: {
              padding: "10px",
              backgroundColor: Color.white,
            },
          }}
          initialSorting={{
            columnIndex: REDACTION_HEADERS.findIndex((h) => h.headerName === state.sortColumn),
            order: state.sortOrder,
          }}
          onSortingChange={updateSorting}
          isLoading={state.isLoading}
        />
      ) : (
        <EmptyState
          type={state.emptyStateType}
          image={emptySvg}
          titleKey="emptyState.noRedactions.title"
          captionKey="emptyState.noRedactions.caption"
          handleClickCaptionLink={() => dispatch(setSearchTerm(""))}
          CreateButton={
            <Button href={routes.createRedaction}>
              <Text resource="button.createRedaction" />
            </Button>
          }
        />
      )}
    </>
  );
};

const StyledPaginationContainer = styled.div`
  padding-bottom: 15px;
`;

const StyledHeaderTopRow = styled.div`
  display: flex;
  align-items: center;
`;

const StyledRedactionListTitle = styled(StyledTablePageTitle)`
  font-size: 1em;
`;

const StyledRedactionName = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
  font-size: 14px;
`;

const StyledCreatedByAdminLabel = styled.div`
  padding: 5px 8px;
  margin-right: 20px;
  font-size: 12px;
  min-width: fit-content;
  background-color: ${Color.indigo10};
  color: ${Color.indigo50};
`;

const StyledButtonBox = styled.div`
  display: flex;
  gap: 15px;
  margin-left: auto;
`;
